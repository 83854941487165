import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import JwtService from '../service/jwt.service'
import API_URL from "@/common/config";
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        /*
        Authentication
        */
        authStatus: '',
        fullName: '',
        authenticated: !!JwtService.getToken(),
        /*
        Settings Page
         */
        settings: [],
        /*
        User Page
         */
        users: [],
        userGroups: [],
        projects: [],
        workplaces: [],
        costCenters: [],
        resources: [],
        forms: [],
        appForms: [],
    },
    getters: {
        /*
        Authentication
         */
        isAuthenticated: state => state.authenticated || !!JwtService.getToken() || false,
        fullName: state => state.fullName || '',
        /*
        Settings Page
         */
        rightsManagement: state => state.settings.filter(value => value.setting.startsWith("disableRightsManagement")) || [],
        synchronisationMode: state => state.settings.filter(value => value.setting.startsWith("synchronisation.mode.")) || [],
        atcJobEnabled: state => state.settings.find(value => value.setting.startsWith("synchronisation.atc.schedule.enabled")) || false,
        atcJobInterval: state => state.settings.find(value => value.setting.startsWith("synchronisation.atc.schedule.interval")) || 300000,
        atcUrl: state => state.settings.find(value => value.setting.startsWith("synchronisation.atc.server.url")) || '',
        atcUsername: state => state.settings.find(value => value.setting.startsWith("synchronisation.atc.server.username")) || '',
        atcDatabase: state => state.settings.find(value => value.setting.startsWith("synchronisation.atc.server.sql-database")) || '',
        atcPassword: state => state.settings.find(value => value.setting.startsWith("synchronisation.atc.server.password")) || '',
        atcTimeZone: state => state.settings.find(value => value.setting.startsWith("synchronisation.atc.server.timezone")) || '',
        /*
        User Page
         */
        userList: state => state.users || [],
        userGroupList: state => state.userGroups || [],
        projectList: state => state.projects || [],
        workplaceList: state => state.workplaces || [],
        costCenterList: state => state.costCenters || [],
        resourceList: state => state.resources || [],
        formList: state => state.forms || [],
        appFormList: state => state.appForms || [],
    },
    mutations: {
        /*
        Authentication
         */
        AUTH_SUCCESS(state, {token, fullName}) {
            state.authStatus = 'success'
            state.fullName = fullName
            state.authenticated = !!token
            JwtService.saveToken(token)
        },
        AUTH_ERROR(state) {
            state.authStatus = 'error'
        },
        AUTH_LOGOUT(state) {
            state.authStatus = ''
            JwtService.destroyToken()
        },
        /*
        Settings Page
         */
        SET_SETTINGS(state, {settings}) {
            state.settings = settings
        },
        /*
        User Page
         */
        SET_USERS(state, {users}) {
            state.users = users
        },
        SET_USER_GROUPS(state, {userGroups}) {
            state.userGroups = userGroups
        },
        SET_PROJECTS(state, {projects}) {
            state.projects = projects
        },
        SET_WORKPLACES(state, {workplaces}) {
            state.workplaces = workplaces
        },
        SET_COST_CENTERS(state, {costCenters}) {
            state.costCenters = costCenters
        },
        SET_RESOURCES(state, {resources}) {
            state.resources = resources
        },
        SET_FORMS(state, {forms}) {
            state.forms = forms
        },
        SET_APP_FORMS(state, {appForms}) {
            state.appForms = appForms
        },
    },
    actions: {
        /*
        Authentication
         */
        login({commit}, {username, password}) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_URL}signin`, {username, password})
                    .then(response => {
                        commit("AUTH_SUCCESS", {token: response.data.token, fullName: response.data.fullName})
                        resolve(response)
                    })
                    .catch(reason => {
                        commit("AUTH_ERROR")
                        reject(reason)
                    })
            })
        },
        logout({commit}) {
            return new Promise(resolve => {
                commit("AUTH_LOGOUT")
                resolve()
            })
        },

        getSettings({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_URL}settings`, {headers: JwtService.getHeader()})
                    .then(response => {
                        response.data.forEach(entry => {
                            switch (entry.type.toLowerCase()) {
                                case "string":
                                    return entry.value;
                                case "boolean":
                                    return entry.value = (entry.value.toLowerCase() === "true");
                                case "integer":
                                    return entry.value = parseInt(entry.value);
                                case "long":
                                    return entry.value = parseInt(entry.value);
                                default:
                                    return null;
                            }
                        })
                        commit("SET_SETTINGS", {settings: response.data})
                        resolve(response)
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 401:
                                router.push('/login')
                                resolve(error)
                                break
                        }
                        reject(error)
                    })
            })
        },
        toggleBooleanSetting({state, dispatch}, settingName) {
            return new Promise((resolve, reject) => {
                let body = {
                    setting: settingName,
                    value: !state.settings.find(entry => entry.setting === settingName)?.value || false,
                    type: "String"
                }
                axios.put(`${API_URL}setting`, body, {headers: JwtService.getHeader()})
                    .then(response => {
                        dispatch('getSettings')
                        resolve(response)
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 401:
                                router.push('/login')
                                resolve(error)
                                break
                        }
                        reject(error)
                    })
            })
        }, //todo: try to substitute with saveSetting()
        saveSetting({dispatch}, setting) {
            return new Promise((resolve, reject) => {
                axios.put(`${API_URL}setting`, setting, {headers: JwtService.getHeader()})
                    .then(response => {
                        dispatch('getSettings')
                        resolve(response)
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 401:
                                router.push('/login')
                                resolve(error)
                                break
                        }
                        reject(error)
                    })
            })
        },
        /*
        User Page
         */
        getUserList({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_URL}users`, {headers: JwtService.getHeader()})
                    .then(response => {
                            commit('SET_USERS', {users: response.data})
                            resolve(response)
                        }
                    )
                    .catch(error => {
                            switch (error.response.status) {
                                case 401:
                                    router.push('/login')
                                    resolve(error)
                                    break
                            }
                            reject(error)
                        }
                    )
            })
        },
        getUserGroupList({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_URL}groups`, {headers: JwtService.getHeader()})
                    .then(response => {
                            commit('SET_USER_GROUPS', {userGroups: response.data})
                            resolve(response)
                        }
                    )
                    .catch(error => {
                            switch (error.response.status) {
                                case 401:
                                    router.push('/login')
                                    resolve(error)
                                    break
                            }
                            reject(error)
                        }
                    )
            })
        },
        getProjectList({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_URL}projects`, {headers: JwtService.getHeader()})
                    .then(response => {
                            commit('SET_PROJECTS', {projects: response.data})
                            resolve(response)
                        }
                    )
                    .catch(error => {
                            switch (error.response.status) {
                                case 401:
                                    router.push('/login')
                                    resolve(error)
                                    break
                            }
                            reject(error)
                        }
                    )
            })
        },
        getWorkplaceList({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_URL}workplaces`, {headers: JwtService.getHeader()})
                    .then(response => {
                            commit('SET_WORKPLACES', {workplaces: response.data})
                            resolve(response)
                        }
                    )
                    .catch(error => {
                            switch (error.response.status) {
                                case 401:
                                    router.push('/login')
                                    resolve(error)
                                    break
                            }
                            reject(error)
                        }
                    )
            })
        },
        getCostCenterList({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_URL}cost_centers`, {headers: JwtService.getHeader()})
                    .then(response => {
                            commit('SET_COST_CENTERS', {costCenters: response.data})
                            resolve(response)
                        }
                    )
                    .catch(error => {
                            switch (error.response.status) {
                                case 401:
                                    router.push('/login')
                                    resolve(error)
                                    break
                            }
                            reject(error)
                        }
                    )
            })
        },
        getResourcesList({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_URL}resources`, {headers: JwtService.getHeader()})
                    .then(response => {
                            commit('SET_RESOURCES', {resources: response.data})
                            resolve(response)
                        }
                    )
                    .catch(error => {
                            switch (error.response.status) {
                                case 401:
                                    router.push('/login')
                                    resolve(error)
                                    break
                            }
                            reject(error)
                        }
                    )
            })
        },
        getFormsList({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_URL}forms`, {headers: JwtService.getHeader()})
                    .then(response => {
                            let forms = response.data
                            forms.forEach(value => {
                                value.form = JSON.parse(value.form)
                            })
                            commit('SET_FORMS', {forms})
                            resolve(response)
                        }
                    )
                    .catch(error => {
                            switch (error.response.status) {
                                case 401:
                                    router.push('/login')
                                    resolve(error)
                                    break
                            }
                            reject(error)
                        }
                    )
            })
        },
        getAppFormsList({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_URL}appForms`, {headers: JwtService.getHeader()})
                    .then(response => {
                            let appForms = response.data
                            appForms.forEach(value => {
                                value.formFields = JSON.parse(value.formFields)
                            })
                            commit('SET_APP_FORMS', {appForms})
                            resolve(response)
                        }
                    )
                    .catch(error => {
                            switch (error.response.status) {
                                case 401:
                                    router.push('/login')
                                    resolve(error)
                                    break
                            }
                            reject(error)
                        }
                    )
            })
        },
    },
    modules: {}
})
