<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn tile class="primary" @click="createAppLayout()">
          <v-icon>mdi-plus</v-icon>
          Add App Layout
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-lg-6 col-sm-12 col-sm-12 col-12" v-for="config in appConfigs" :key="config.id">
        <v-list elevation="4" class="pb-0">
          <v-list-item>
            <v-list-item-icon>
              <v-icon large>mdi-format-list-bulleted-square</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h6">{{ config.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-btn color="warning" icon @click="editAppLayout(config)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="copyAppLayout(config)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn icon color="primary" @click="addFeature(config)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn color="error" icon @click="deleteAppLayout(config)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-list-item-action>

          </v-list-item>
          <v-divider class="blue-grey"/>
          <v-list-item class="ml-0 pl-0 mr-0 pr-0">
            <v-tabs>
              <template v-for="(feature, index) in config.features">
                <v-tab :key="index">{{ feature.type }}</v-tab>
                <v-tab-item :key="index">

                  <v-toolbar elevation="4">
                    <v-toolbar-items class="pr-5">
                      <v-icon>mdi-{{ feature.icon }}</v-icon>
                    </v-toolbar-items>
                    <v-toolbar-title>{{ feature.name }} ({{ feature.shortName }})</v-toolbar-title>
                    <v-spacer/>
                    <v-btn icon @click="editFeature(config, feature)">
                      <v-icon color="warning">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="copyListItem(config, config.features, feature)">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn icon @click="deleteListItem(config, config.features, index)">
                      <v-icon color="error" >mdi-delete</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <template v-for="(action, index) in feature.actions">
                    <v-list class="pb-0" :key="index">
                      <v-list-item >
                        <v-list-item-icon>
                          <v-icon :color="action.color" x-large>mdi-{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="text-h5">{{ action.label }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row>
                            <v-btn
                                icon
                                color="secondary"
                                @click="moveInList(config, feature.actions, index, index - 1)"
                                :disabled="index === 0"
                            >
                              <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                color="secondary"
                                @click="moveInList(config, feature.actions, index, index + 1)"
                                :disabled="index === (feature.actions.length - 1)"
                            >
                              <v-icon>mdi-arrow-down</v-icon>
                            </v-btn>
                            <v-btn icon color="warning" @click="editAction(config, action)">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon @click="copyListItem(config, feature.actions, action)">
                              <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                            <v-btn icon color="error" @click="deleteListItem(config, feature.actions, index)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider/>
                      <!-- region Pages -->
                      <v-list class="pa-0">
                        <template v-for="(page, index) in action.pages">
                          <v-list-item dense sub-group no-action :key="page.label">
                            <v-list-item-icon>
                              <template v-for="type in pageTypes">
                                <v-icon :key="type" v-if="page.type === type.type">{{ type.icon }}</v-icon>
                              </template>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ page.title }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-row>
                                <v-btn
                                    icon
                                    color="secondary"
                                    @click="moveInList(config, action.pages, index, index - 1)"
                                    :disabled="index === 0"
                                >
                                  <v-icon>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    color="secondary"
                                    @click="moveInList(config, action.pages, index, index + 1)"
                                    :disabled="index === (action.pages.length - 1)"
                                >
                                  <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>
                                <v-btn icon color="warning" @click="editPage(config, page)">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon color="error" @click="deleteListItem(config, action.pages, index)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-row>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider :key="index"/>
                        </template>
                        <v-list-item>
                          <v-spacer/>
                          <v-list-item-action>
                            <v-btn tile color="primary" @click="addPage(config, action)">
                              <v-icon left>mdi-plus</v-icon>
                              Add Page
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <!-- endregion -->
                      <v-divider class="black"/>
                    </v-list>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item>
                      <v-spacer/>
                      <v-list-item-action>
                        <v-btn tile color="primary" @click="addAction(config, feature)">
                          <v-icon left>mdi-plus</v-icon>
                          Add Action
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-tab-item>
              </template>
            </v-tabs>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <!-- region App Layout Dialog -->
    <v-dialog
        persistent
        v-model="dialogAppLayout"
        max-width="800"
        scrollable
        @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            <span class="headline">ADD/EDIT/COPY APP LAYOUT</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4 pr-10 pl-10 pb-4">
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="appLayout.name"
                  label="Name"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete
                  v-model="appLayout.userGroup"
                  label="User group"
                  prepend-icon="mdi-account-multiple"
                  :items="userGroupList"
                  item-text="description"
                  return-object
                  item-value="id"
              >
                <template v-slot:selection="{ item }">
                  {{ item.description }} ({{ item.id }})
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.description }} ({{ item.id }})</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-file-input
                  v-model="drawerImagePicker"
                  label="New drawer image"
                  persistent-hint
                  hint="Image that gets displayed in navigation drawer of app (PNG only)"
                  @change="drawerImageToBase64"
                  append-outer-icon="mdi-delete"
                  @click:append-outer="resetDrawerImage"
              />
              <v-card elevation="0" v-if="appLayout.drawerImage">
                <v-card-title>Current image</v-card-title>
                <v-img
                    :src="'data:image/png;base64,' + appLayout.drawerImage"
                    height="130"
                    contain
                />
              </v-card>

            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="saveAppLayout">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->

    <!-- region Feature Dialog -->
    <v-dialog
        persistent
        v-model="dialogFeature"
        max-width="800"
        scrollable
        @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            <span class="headline">ADD/EDIT FEATURE</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4 pr-10 pl-10 pb-4">
          <v-row dense>
            <v-col>
              <v-select
                  v-model="feature.type"
                  label="Feature type"
                  item-value="type"
                  :items="featureTypes"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">{{ item.icon }}</v-icon>
                  {{ item.label }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="feature.name"
                  label="Name"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="feature.shortName"
                  label="Short name"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete
                  v-model="feature.icon"
                  label="Icon"
                  :items="icons"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">mdi-{{ item }}</v-icon>
                  {{ item }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>mdi-{{ item }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-switch
                  v-model="feature.bottomNavigation"
                  label="Show Feature in bottom navigation"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="saveFeature">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->

    <!-- region Action Dialog -->
    <v-dialog
        persistent
        v-model="dialogAction"
        max-width="800"
        scrollable
        @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            <span class="headline">ADD/EDIT ACTION</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4 pr-10 pl-10 pb-4">
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="action.label"
                  label="Label"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete
                  v-model="action.icon"
                  label="Icon"
                  :items="icons"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">mdi-{{ item }}</v-icon>
                  {{ item }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>mdi-{{ item }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-text-field
                  v-model="action.color"
                  type="color"
                  label="Icon color"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="action.fontSize"
                  label="Font size"
                  type="number"
                  prepend-icon="mdi-format-size"
                  placeholder="22"
              />
            </v-col>
            <v-col>
              <v-text-field
                  v-model="action.verticalPadding"
                  label="Vertical padding"
                  type="number"
                  prepend-icon="mdi-align-vertical-distribute"
                  placeholder="3"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-switch
                  v-model="action.allowOffline"
                  label="Allow offline bookings"
              />
            </v-col>
            <v-col>
              <v-switch
                  v-model="action.saveGpsPosition"
                  label="Save GPS position"
                  hint="Saves the gps positions of the bookings. THIS OPTION SHOULD ONLY USED FOR DEBUGING LOCATIONS"
                  persistent-hint
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                  v-model="action.template"
                  label="Last action template"
              />
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="saveAction">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->

    <!-- region Page Dialog -->
    <v-dialog
        persistent
        v-model="dialogPage"
        max-width="800"
        scrollable
        @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            <span class="headline">ADD/EDIT PAGE</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4 pr-10 pl-10 pb-4">
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="page.title"
                  label="Title"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select
                  v-model="page.type"
                  label="Page type"
                  item-value="type"
                  :items="pageTypes"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">{{ item.icon }}</v-icon>
                  {{ item.label }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row v-if="(page.type === 'form' && licenses.filter(value => value.productNumber === '48102') < 1)">
            <v-alert
                dense
                border="left"
                type="warning"
            >
              For Forms a additional license is required. The only Forms that will work correctly without license are "Remark" and "Remark (required)"
            </v-alert>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="page.timeout"
                  type="number"
                  label="Timeout"
                  placeholder="60"
                  min="0"
                  suffix="s"
              />
            </v-col>
          </v-row>

          <!-- region Time account page -->
          <div v-if="page.type === 'time-account'">
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="page.timeAccount"
                    label="Time account"
                    :items="timeAccountList"
                    item-text="name"
                    item-value="number"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name }} ({{ item.number }})
                  </template>
                  <template v-slot:item="{ active, item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }} ({{ item.number }})</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </div>
          <!-- endregion -->

          <!-- region Form page -->
          <div v-if="page.type === 'form'">
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="page.form"
                    label="Form"
                    prepend-icon="mdi-clipboard-text"
                    :items="appFormList"
                    item-text="name"
                    item-value="id"
                />
              </v-col>
            </v-row>
          </div>
          <!-- endregion -->

          <!-- region List page -->
          <div v-if="page.type === 'list'">
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="page.icon"
                    label="Icon"
                    :items="icons"
                >
                  <template v-slot:selection="{ item }">
                    <v-icon class="ml-4 mr-4">mdi-{{ item }}</v-icon>
                    {{ item }}
                  </template>
                  <template v-slot:item="{ active, item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-icon>
                        <v-icon>mdi-{{ item }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="page.color"
                    type="color"
                    label="Color"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                    v-model="page.listType"
                    label="List type"
                    item-value="type"
                    :items="fenceTypes"
                >
                  <template v-slot:selection="{ item }">
                    <v-icon class="ml-4 mr-4">{{ item.icon }}</v-icon>
                    {{ item.label }}
                  </template>
                  <template v-slot:item="{ active, item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row dense v-if="page.listType === 'Project'">
              <v-col>
                <v-switch
                    v-model="page.projectState"
                    label="Select project state after project"
                />
              </v-col>
            </v-row>
            <v-row dense v-if="page.listType === 'Project' && page.projectState">
              <v-col>
                <v-text-field
                    v-model="page.projectStateFilter"
                    label="SQL Filter for project states"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-switch
                    v-model="page.gpsFilter"
                    label="Filter List based on GPS position"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="page.listFilter"
                    label="SQL Filter for displayed entities"
                />
              </v-col>
            </v-row>
          </div>
          <!-- endregion -->

          <!-- region GPS page -->
          <div v-if="page.type === 'gps'">
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="page.minAccuracy"
                    type="number"
                    suffix="m"
                    label="Minimum accuracy"
                    placeholder="150"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-switch
                    v-model="page.gpsCheck"
                    label="Check for a valid geofence"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                    v-model="page.checkType"
                    multiple
                    :items="fenceTypes"
                    item-value="type"
                    label="Check type"
                >
                  <template v-slot:selection="{ item }">
                    <v-icon class="ml-4 mr-4">{{ item.icon }}</v-icon>
                    {{ item.label }}
                  </template>
                  <template v-slot:item="{ active, item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </div>
          <!-- endregion -->

          <!-- region QR code page -->
          <div v-if="page.type === 'qr-code'">
            <v-row dense>
              <v-col>
                <v-switch
                    v-model="page.qrSkipFilled"
                    label="Skip already filled fences"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-switch
                    v-model="page.qrValidateSignature"
                    label="Validate signature of qr code"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-switch
                    v-model="page.secondaryCamera"
                    label="Flip camera to secondary camera"
                />
              </v-col>
            </v-row>
          </div>
          <!-- endregion -->

        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="savePage">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- endregion -->

    <!-- region Delete App Layout Dialog -->
    <v-dialog
        persistent
        v-model="dialogDeleteAppLayout"
        max-width="800"
        scrollable
        @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            <span class="headline">DELETE APP LAYOUT "{{appLayout.name}}"</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4 pr-10 pl-10 pb-4">
          <v-row dense>
            <v-col>
              Should the "{{appLayout.name}}" be deleted?
              <br>
              <b class="error--text">IMPORTANT: This cannot be undone!</b>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="error" @click="confirmDeletionOfAppLayout">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->

    <!-- region Delete Dialog -->
    <v-dialog
        persistent
        v-model="dialogDeleteList"
        max-width="800"
        scrollable
        @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            <span class="headline">DELETE "{{deleteIdentifier}}" FROM LIST</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4 pr-10 pl-10 pb-4">
          <v-row dense>
            <v-col>
              Should the "{{deleteIdentifier}}" be deleted?
              <br>
              <b class="error--text">IMPORTANT: This cannot be undone!</b>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="error" @click="confirmDeletion">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->
  </v-container>
</template>

<script>
import MDI_ICONS from "@/common/mdi_icons";
import FEATURE_TYPES from "@/common/feature_types";
import FENCE_TYPES from "@/common/fence_types";
import PAGE_TYPES from "@/common/page_types";
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";
import {mapGetters} from "vuex";

export default {
  data: function () {
    return {
      icons: MDI_ICONS,
      pageTypes: PAGE_TYPES,
      featureTypes: FEATURE_TYPES,
      fenceTypes: FENCE_TYPES,
      drawerImagePicker: {},
      appConfigs: [],
      timeAccountList: [],
      dialogAppLayout: false,
      dialogFeature: false,
      dialogAction: false,
      dialogPage: false,
      dialogDeleteList: false,
      dialogDeleteAppLayout: false,
      appLayout: {},
      feature: {},
      action: {},
      page: {},
      deleteIndex: -1,
      deleteList: [],
      licenses:[]
    }
  },
  mounted() {
    this.getAppConfigs()
    this.getTimeAccounts()
    this.getLicenses()
    this.$store.dispatch("getUserGroupList")
    this.$store.dispatch("getAppFormsList")
  },
  methods: {
    getLicenses() {
      axios.get(`${API_URL}licenses`, {headers: JwtService.getHeader()})
          .then(value => this.licenses = value.data)
          .catch(reason => console.log(reason))
    },
    /**
     * Opens the dialog to create a new App Layout
     */
    createAppLayout() {
      this.dialogAppLayout = true
    },

    /**
     * Opens the dialog to edit a App Layout
     * @param appLayout The App Layout to edit
     */
    editAppLayout(appLayout) {
      this.appLayout = Object.assign({}, appLayout)
      this.dialogAppLayout = true
    },

    /**
     * Function to save the entered or modified App Layout
     */
    saveAppLayout() {
      let appLayout = Object.assign({}, this.appLayout)
      if ('id' in this.appLayout) {
        // Editing
        appLayout.features = JSON.stringify(appLayout.features)
      } else {
        // Creating
        if ('features' in appLayout) {
          appLayout.features = JSON.stringify(appLayout.features)
        } else {
          appLayout.features = '[]'
        }
      }
      this.writeAppLayout(appLayout)
      this.closeDialog()
    },

    writeAppLayout(appLayout) {
      axios.post(`${API_URL}appConfig`, appLayout, {headers: JwtService.getHeader()})
          .then(() => {
            this.getAppConfigs()
          })
    },

    /**
     * Converts a File to a Base64 String and adds it to the App Layout
     * @param event
     */
    drawerImageToBase64(event) {
      if (event.type === 'image/png' && event.size <= 102400) {
        let reader = new FileReader();
        reader.readAsDataURL(event)
        reader.onload = () => {
          console.log(reader.result.split('base64,')[1])
          this.appLayout.drawerImage = reader.result.split('base64,')[1];
          this.$forceUpdate();
        };
      } else {
        this.drawerImagePicker = {}
      }
    },

    /**
     * Function that clears the drawerImage
     */
    resetDrawerImage() {
      this.appLayout.drawerImage = ""
      this.$forceUpdate();
    },

    /**
     * Opens dialog to copy the app layout
     * @param appLayout App layout to copy
     */
    copyAppLayout(appLayout) {
      delete appLayout.id
      delete appLayout.userGroup
      this.appLayout = Object.assign({}, appLayout)
      this.dialogAppLayout = true
    },

    /**
     * Opens dialog to delete app layouts
     * @param appLayout to delete
     */
    deleteAppLayout(appLayout) {
      this.deleteIndex = appLayout.id
      this.appLayout = appLayout
      this.dialogDeleteAppLayout = true
    },

    /**
     * Function that deletes the terminal permanently
     */
    confirmDeletionOfAppLayout() {
      axios.delete(`${API_URL}appConfig/${this.deleteIndex}`, {headers: JwtService.getHeader()})
          .then(() => {
            this.getAppConfigs()
          })
      this.closeDialog()
    },

    /**
     * Function to open dialog to add a new feature
     * @param appLayout
     */
    addFeature(appLayout) {
      this.appLayout = appLayout
      this.dialogFeature = true
    },

    /**
     * Opens the dialog to edit a Feature
     * @param appLayout The App Layout the feature belongs to
     * @param feature The Feature
     */
    editFeature(appLayout, feature) {
      this.appLayout = appLayout
      this.feature = feature
      this.dialogFeature = true
    },

    /**
     * Function to save the entered or modified Feature
     */
    saveFeature() {
      let appLayout = Object.assign({}, this.appLayout)
      let feature = Object.assign({}, this.feature)

      if ('id' in this.feature) {
        // Editing
        appLayout.features = JSON.stringify(appLayout.features)
      } else {
        // Creating
        feature.id = this.$uuid.v1()
        feature.actions = []
        appLayout.features.push(feature)
        appLayout.features = JSON.stringify(appLayout.features)
      }
      this.writeAppLayout(appLayout)
      this.closeDialog()
    },

    /**
     * Opens the dialog to add a new Action
     * @param appLayout Layout the Action belongs to
     * @param feature Feature the Action gets added
     */
    addAction(appLayout, feature) {
      this.appLayout = appLayout
      this.feature = feature
      this.dialogAction = true
    },

    /**
     * Opens the dialog to edit a Action
     * @param appLayout The App Layout the action belongs to
     * @param action The action
     */
    editAction(appLayout, action) {
      this.appLayout = appLayout
      this.action = action
      this.dialogAction = true
    },

    /**
     * Function to save the entered or modified Action
     */
    saveAction() {
      if ('id' in this.action) {
        // Editing
        let appLayout = Object.assign({}, this.appLayout)
        appLayout.features = JSON.stringify(appLayout.features)
        axios.post(`${API_URL}appConfig`, appLayout, {headers: JwtService.getHeader()})
            .then(() => {
              this.getAppConfigs()
            })
      } else {
        // Creating
        this.action.id = this.$uuid.v1()
        this.action.pages = []

        this.feature.actions.push(this.action)
        let appLayout = Object.assign({}, this.appLayout)
        appLayout.features = JSON.stringify(appLayout.features)
        this.writeAppLayout(appLayout)
      }
      this.closeDialog()
    },

    /**
     * Opens the dialog to add a new Page
     * @param appLayout Layout the Page belongs to
     * @param action Action the Page gets added
     */
    addPage(appLayout, action) {
      this.appLayout = appLayout
      this.action = action
      this.dialogPage = true
    },

    /**
     * Opens the dialog to edit a Page
     * @param appLayout The App Layout the action belongs to
     * @param page The page
     */
    editPage(appLayout, page) {
      this.appLayout = appLayout
      this.page = page
      this.dialogPage = true
    },

    /**
     * Function to save the entered or modified Page
     */
    savePage() {
      if ('id' in this.page) {
        // Editing
        let appLayout = Object.assign({}, this.appLayout)
        appLayout.features = JSON.stringify(appLayout.features)
        this.writeAppLayout(appLayout)
      } else {
        // Creating
        this.page.id = this.$uuid.v1()

        this.action.pages.push(this.page)
        let appLayout = Object.assign({}, this.appLayout)
        appLayout.features = JSON.stringify(appLayout.features)
        this.writeAppLayout(appLayout)
      }

      this.closeDialog()
    },

    /**
     * Function to close all open dialogs and restore starting state
     */
    closeDialog() {
      if(this.dialogAppLayout) this.dialogAppLayout = !this.dialogAppLayout
      if(this.dialogFeature) this.dialogFeature = !this.dialogFeature
      if(this.dialogAction) this.dialogAction = !this.dialogAction
      if(this.dialogPage) this.dialogPage = !this.dialogPage
      if(this.dialogDeleteList) this.dialogDeleteList = !this.dialogDeleteList
      if(this.dialogDeleteAppLayout) this.dialogDeleteAppLayout = !this.dialogDeleteAppLayout

      this.appLayout = {}
      this.feature = {}
      this.action = {}
      this.page = {}
      this.deleteList = []
      this.deleteIndex = -1
      this.getAppConfigs()
    },

    /**
     * Function to order Pages and Actions
     * @param config the App Layout to be saved after ordering
     * @param list the List where the Action or Page is contained
     * @param from the Index from where the Action/Page is moved
     * @param to the Index to where the Action/Page is moved
     */
    moveInList(config, list, from, to) {
      let move = list.splice(from, 1)[0]
      list.splice(to, 0, move)

      let appLayout = Object.assign({}, config)
      appLayout.features = JSON.stringify(appLayout.features)
      this.writeAppLayout(appLayout)
    },

    /**
     * Opens the dialog to confirm the deletion of an list item
     * @param config the applayout to save
     * @param list the List in which the change should occure
     * @param index the index of the item to be removed
     */
    deleteListItem(config, list, index) {
      this.appLayout = config
      this.deleteList = list
      this.deleteIndex = index
      this.dialogDeleteList = true
    },

    /**
     * Function to save the deletion of an item
     */
    confirmDeletion(){
      this.deleteList.splice(this.deleteIndex, 1)
      let appLayout = Object.assign({}, this.appLayout)
      appLayout.features = JSON.stringify(appLayout.features)
      this.writeAppLayout(appLayout)
      this.closeDialog()
    },

    /**
     * Function to copy a List action
     * @param config App Layout to save the changes to
     * @param list List in which the Item is cloned
     * @param item The item to clone
     */
    copyListItem(config, list, item) {
      let itemToClone = Object.assign({}, item)
      itemToClone.id = this.$uuid.v1()
      list.push(itemToClone)

      let appLayout = Object.assign({}, config)
      appLayout.features = JSON.stringify(appLayout.features)
      this.writeAppLayout(appLayout)
      this.closeDialog()
    },

    /**
     * Function that gets all current configs
     */
    getAppConfigs() {
      axios.get(`${API_URL}appConfigs`, {headers: JwtService.getHeader()})
          .then(response => {
            response.data.forEach(value => {
              value.features = JSON.parse(value.features)
            })
            this.appConfigs = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },

    getTimeAccounts: function () {
      axios.get(`${API_URL}appConfig/timeAccounts`, {headers: JwtService.getHeader()})
          .then(response => {
            this.timeAccountList = response.data
          })
    }
  },
  computed: {
    ...mapGetters([
      "userGroupList",
      "appFormList"
    ]),
    deleteIdentifier: function () {
      if (this.deleteList.length > 0 && this.deleteIndex !== -1) {
        if ('title' in this.deleteList[this.deleteIndex]) return this.deleteList[this.deleteIndex].title
        if ('label' in this.deleteList[this.deleteIndex]) return this.deleteList[this.deleteIndex].label
        if ('name' in this.deleteList[this.deleteIndex])
          if ('shortName' in this.deleteList[this.deleteIndex])
            return `${this.deleteList[this.deleteIndex].name} (${this.deleteList[this.deleteIndex].shortName})`
      }
      return ''

    }
  }
}

</script>

<style scoped>

</style>
