<template>
  <v-container fluid>
    <v-data-table
        :headers="headers"
        :items="userList"
        :search="search"
        class="elevation-1"
        :items-per-page="20"
        dense
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>
            <v-icon>mdi-account</v-icon>
            Users
          </v-toolbar-title>
          <v-spacer/>
            <v-text-field
                v-model="search"
                placeholder="Search"
                append-icon="mdi-magnify"
            />
          <v-spacer/>
          <v-btn
              color="primary"
              @click="createItem"
          >
            Create new user
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.roles="{ item }">
        <v-chip
            v-for="entry in item.roles"
            :key="entry.id"
            class="ma-1"
            small
            color="secondary"
        >{{ entry.description }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            color="warning"
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            color="error"
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:item.active="{ item }">
        <v-icon
            :color="item.active ? 'success' : 'error'"
        >
          {{ item.active ? 'mdi-check' : 'mdi-close' }}
        </v-icon>
      </template>

      <template v-slot:item.loginActive="{ item }">
        <v-icon
            :color="item.loginActive ? 'success' : 'error'"
        >
          {{ item.loginActive ? 'mdi-check' : 'mdi-close' }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
        v-model="dialog"
        fullscreen
        scrollable
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <v-btn
                icon
                dark
                @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <span class="headline">{{ getFormDialogTitle }} - {{ editedItem.number }}</span>
            <v-spacer/>
            <v-toolbar-items>
              <v-btn
                  dark
                  text
                  @click="saveItem"
              >
                {{ (editing) ? 'save changes' : 'Create user' }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container v-if="saving" >
            <v-row justify="center" style="margin-top: 50px">
              <v-col cols="1">
                <v-progress-circular size="150" width="10" indeterminate color="primary">
                  saving
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="!saving">
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.number"
                    :rules="[() => !!editedItem.number || 'This field is required']"
                    :readonly="editing"
                    label="Number"
                    prepend-icon="mdi-key"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.username"
                    :rules="[() => !!editedItem.username || 'This field is required']"
                    label="Username"
                    prepend-icon="mdi-account"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.firstname"
                    label="Firstname"
                    prepend-icon="mdi-form-textbox"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.lastname"
                    label="Lastname"
                    prepend-icon="mdi-form-textbox"
                />
              </v-col>
            </v-row>

            <v-row dense v-if="editing">
              <v-col>
                <v-switch v-model="setPassword" label="Set password for user"/>
              </v-col>
            </v-row>
            <v-row dense v-if="!editing || setPassword">
              <v-col dense>
              <v-row dense>
                <v-col>
                  <v-text-field
                      v-model="password"
                      :rules="[() => !!password || 'This field is required']"
                      label="Password"
                      type="password"
                      prepend-icon="mdi-lock"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="passwordConfirm"
                      :rules="[
                          () => !!passwordConfirm || 'This field is required',
                          () => password === passwordConfirm || 'Passwords do not match'
                          ]"
                      label="Repeat"
                      type="password"
                      prepend-icon="mdi-lock"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col dense>
                  Min. 8 Characters
                </v-col>
              </v-row>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.email"
                    label="E-mail"
                    type="email"
                    prepend-icon="mdi-email"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="userGroupsForUser"
                    :items="userGroupList"
                    item-text="description"
                    multiple
                    return-object
                    small-chips
                    deletable-chips
                    prepend-icon="mdi-account-group"
                    label="User groups"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.company"
                    label="Company"
                    prepend-icon="mdi-domain"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.group"
                    label="Group"
                    prepend-icon="mdi-group"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.costCenter"
                    label="Cost center"
                    prepend-icon="mdi-tag"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.startdate"
                    label="Start date"
                    type="date"
                    prepend-icon="mdi-calendar-start"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.enddate"
                    label="End date"
                    type="date"
                    prepend-icon="mdi-calendar-end"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.latitude"
                    label="Latitude"
                    type="number"
                    prepend-icon="mdi-latitude"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.longitude"
                    label="Longitude"
                    type="number"
                    prepend-icon="mdi-longitude"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.radius"
                    label="Radius"
                    type="number"
                    prepend-icon="mdi-map-marker-radius"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                    v-model="editedItem.roles"
                    :items="roleList"
                    item-text="description"
                    multiple
                    label="Roles"
                    small-chips
                    return-object
                    prepend-icon="mdi-scale-balance"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-switch
                    v-model="editedItem.active"
                    label="Active"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-switch
                    v-model="editedItem.loginActive"
                    label="Login active"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <h1 class="text-h5">Rights</h1>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="usersForUser"
                    :items="userList"
                    label="Users"
                    prepend-icon="mdi-account-multiple"
                    append-outer-icon="mdi-select-multiple"
                    @click:append-outer="usersForUser = userList"
                    multiple
                    return-object
                    small-chips
                    deletable-chips
                    :item-text="item => `${item.firstname} ${item.lastname} (${item.number})`"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="projectsForUser"
                    :items="projectList"
                    label="Projects"
                    prepend-icon="mdi-chart-gantt"
                    append-outer-icon="mdi-select-multiple"
                    @click:append-outer="projectsForUser = projectList"
                    multiple
                    return-object
                    small-chips
                    deletable-chips
                    :item-text="item => `${item.name} (${item.number})`"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="workplacesForUser"
                    :items="workplaceList"
                    label="Workplaces"
                    prepend-icon="mdi-office-building-marker"
                    append-outer-icon="mdi-select-multiple"
                    @click:append-outer="workplacesForUser = workplaceList"
                    multiple
                    return-object
                    small-chips
                    deletable-chips
                    :item-text="item => `${item.name} (${item.number})`"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="costCentersForUser"
                    :items="costCenterList"
                    label="Cost Centers"
                    prepend-icon="mdi-tag"
                    append-outer-icon="mdi-select-multiple"
                    @click:append-outer="costCentersForUser = costCenterList"
                    multiple
                    return-object
                    small-chips
                    deletable-chips
                    :item-text="item => `${item.name} (${item.number})`"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="resourcesForUser"
                    :items="resourceList"
                    label="Resources"
                    prepend-icon="mdi-toolbox"
                    append-outer-icon="mdi-select-multiple"
                    @click:append-outer="resourcesForUser = resourceList"
                    multiple
                    small-chips
                    return-object
                    deletable-chips
                    :item-text="item => `${item.name} (${item.number})`"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogDelete"
        fullscreen
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn
              icon
              dark
              @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Delete user "{{ editedItem.number }} - {{ editedItem.username }}"</v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <v-btn
                text
                dark
                @click="deletionConfirmed"
            >
              Confirm deletion
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-10">
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md8>
              <h1 class="text-h2 d-flex justify-center">Schould user "{{ editedItem.number }} - {{
                  editedItem.username
                }}" be deleted?</h1>
            </v-flex>
          </v-layout>
        </v-card-text>

      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";
import {mapGetters} from "vuex";

export default {
  name: "Users",
  data: function () {
    return {
      //region Local data
      search: '',
      dialog: false,
      dialogDelete: false,
      editing: false,
      editedItem: {},
      setPassword: false,
      password: '',
      passwordConfirm: '',
      saving: false,
      headers: [
        {text: 'Number', value: 'number'},
        {text: 'Firstname', value: 'firstname'},
        {text: 'Lastname', value: 'lastname'},
        {text: 'Username', value: 'username'},
        {text: 'E-Mail', value: 'email'},
        {text: 'Company', value: 'company'},
        {text: 'Start Date', value: 'startDate'},
        {text: 'End Date', value: 'endDate'},
        {text: 'Latitude', value: 'latitude'},
        {text: 'Longitude', value: 'longitude'},
        {text: 'Radius', value: 'radius'},
        {text: 'Login Active', value: 'loginActive'},
        {text: 'Active', value: 'active'},
        {text: 'Group', value: 'grouping'},
        {text: 'Roles', value: 'roles'},
        {text: 'Actions', value: 'actions', width: 100},
      ],
      //endregion

      //region API data
      roleList: [],
      usersForUser: [],
      userGroupsForUser: [],
      projectsForUser: [],
      workplacesForUser: [],
      costCentersForUser: [],
      resourcesForUser: [],
      //endregion
    }
  },
  mounted() {
    axios.get(`${API_URL}roles`, {headers: JwtService.getHeader()})
        .then(response => {
          this.roleList = response.data

        })
        .catch(error => {
          console.log(error)
        })

    this.$store.dispatch("getUserList")
    this.$store.dispatch("getUserGroupList")
    this.$store.dispatch("getProjectList")
    this.$store.dispatch("getWorkplaceList")
    this.$store.dispatch("getCostCenterList")
    this.$store.dispatch("getResourcesList")
  },
  methods: {
    editItem(item) {
      this.editing = true;
      this.editedItem = Object.assign({}, item)
      this.getUsersForUser(this.editedItem.number)
      this.getUserGroupsForUser(this.editedItem.number)
      this.getProjectsForUser(this.editedItem.number)
      this.getWorkplacesForUser(this.editedItem.number)
      this.getCostCentersForUser(this.editedItem.number)
      this.getResourcesForUser(this.editedItem.number)
      this.dialog = true
    },
    createItem() {
      this.editing = false;
      this.dialog = true
    },
    async saveItem() {
      this.saving = true

      if (this.setPassword && (this.password !== this.passwordConfirm || this.password.trim().length < 8)) {
        this.saving = false
        return
      }      // if ('password' in this.editedItem && 'repeat' in this.editedItem) {
      //   if (this.editedItem.password === this.editedItem.repeat) {
      //     delete this.editedItem.repeat
      //   } else {
      //     this.saving = false
      //     return
      //   }
      // }

      if (this.editing) {
        await axios.put(`${API_URL}user`, this.editedItem, {headers: JwtService.getHeader()})
            .then(() => {
              this.$store.dispatch("getUserList")
            })
            .catch(error => {
              console.log(error)
            })
      } else {
        await axios.post(`${API_URL}user`, this.editedItem, {headers: JwtService.getHeader()})
            .then(() => {
              this.$store.dispatch("getUserList")
            })
            .catch(error => {
              console.log(error)
            })
      }

      await axios.put(`${API_URL}user/${this.editedItem.number}/projects`, this.projectsForUser, {headers: JwtService.getHeader()})
          .catch(error => {
            console.log(error)
          })
      await axios.put(`${API_URL}user/${this.editedItem.number}/workplaces`, this.workplacesForUser, {headers: JwtService.getHeader()})
          .catch(error => {
            console.log(error)
          })
      await axios.put(`${API_URL}user/${this.editedItem.number}/cost_centers`, this.costCentersForUser, {headers: JwtService.getHeader()})
          .catch(error => {
            console.log(error)
          })
      await axios.put(`${API_URL}user/${this.editedItem.number}/resources`, this.resourcesForUser, {headers: JwtService.getHeader()})
          .catch(error => {
            console.log(error)
          })
      await axios.put(`${API_URL}user/${this.editedItem.number}/users`, this.usersForUser, {headers: JwtService.getHeader()})
          .catch(error => {
            console.log(error)
          })

      await axios.put(`${API_URL}user/${this.editedItem.number}/groups`, this.userGroupsForUser, {headers: JwtService.getHeader()})
          .catch(error => {
            console.log(error)
          })

      if (this.setPassword && this.password === this.passwordConfirm && this.password.trim().length >= 8) {
        await axios.post(`${API_URL}user/password_change`,
            {userNumber: this.editedItem.number, password: this.password},
            {headers: JwtService.getHeader()})
            .then(() => {
              console.log("response");
            })
      }

      this.closeDialog()
    },
    deleteItem(item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    deletionConfirmed() {
      axios.delete(`${API_URL}user/${this.editedItem.number}`, {headers: JwtService.getHeader()})
          .then(() => {
            this.$store.dispatch("getUserList")
          })
          .catch(error => {
            console.log(error)
          })

      this.closeDialog()
    },
    closeDialog() {
      if (this.dialog) this.dialog = false;
      if (this.dialogDelete) this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {}
        this.usersForUser = []
        this.projectsForUser = []
        this.workplacesForUser = []
        this.costCentersForUser = []
        this.resourcesForUser = []
        this.setPassword = false
        this.password = ''
        this.passwordConfirm = ''
        this.saving = false
      });
    },

    //region Functions to get rights for user
    getUsersForUser(userNumber) {
      axios.get(`${API_URL}user/${userNumber}/users`, {headers: JwtService.getHeader()})
          .then(response => {
            this.usersForUser = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getUserGroupsForUser(userNumber) {
      axios.get(`${API_URL}user/${userNumber}/groups`, {headers: JwtService.getHeader()})
          .then(response => {
            this.userGroupsForUser = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getProjectsForUser(userNumber) {
      axios.get(`${API_URL}user/${userNumber}/projects`, {headers: JwtService.getHeader()})
          .then(response => {
            this.projectsForUser = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getWorkplacesForUser(userNumber) {
      axios.get(`${API_URL}user/${userNumber}/workplaces`, {headers: JwtService.getHeader()})
          .then(response => {
            this.workplacesForUser = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getCostCentersForUser(userNumber) {
      axios.get(`${API_URL}user/${userNumber}/cost_centers`, {headers: JwtService.getHeader()})
          .then(response => {
            this.costCentersForUser = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getResourcesForUser(userNumber) {
      axios.get(`${API_URL}user/${userNumber}/resources`, {headers: JwtService.getHeader()})
          .then(response => {
            this.resourcesForUser = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    //endregion
  },
  computed: {
    ...mapGetters([
      "userList",
      "projectList",
      "workplaceList",
      "costCenterList",
      "resourceList",
        "userGroupList",
    ]),
    getFormDialogTitle() {
      return (this.editing) ? "Edit user" : "Create new user"
    }
  }
}
</script>

<style scoped>

</style>
